/**
 * Main app
 *
 */
"use strict";

import AmericorForm from './form.js';
import AmericorPhone from './phone.js';
import CallScheduler from "./call-scheduler.js"
import Cookies from "js-cookie";

class Toolkit {
  /**
   * Base URL
   * 
   * @param string base_url
   */
  static base_url = new URL(document.currentScript.src).origin;

  /**
   * Constructor
   * 
   */
  constructor()
  {
    const phone         = new AmericorPhone( Toolkit.base_url );
    const callScheduler = new CallScheduler();
  }

  /**
   * Initialize form
   * 
   * @param object config
   */
  init( config = {} )
  {
    const visitor_uuid = this.getVisitorUUID();

    const form = new AmericorForm( Toolkit.base_url, {...config, ...{ visitor_uuid: visitor_uuid }} );
  }

  /**
   * Get visitor UUID from PostHog cookie
   * 
   * @return string
   */
  getVisitorUUID()
  {
    let cookieData = Cookies.get('_ph_bootstrapData');

    // Test on apply.americor.com only
    let allowedDomains = [
      'apply.americor.com', 
      'staging.apply.americor.com', 
      'afs.americor.com', 
      'staging.afs.americor.com', 
      'localhost'
    ];

    if ( ! allowedDomains.includes(document.location.hostname) || ! cookieData ) return;

    return JSON.parse(cookieData)?.distinctID || self.crypto.randomUUID();
  }
}

window.toolkit = new Toolkit();
