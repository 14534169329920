/**
 * Jornaya
 *
 */
"use strict";

export default class Jornaya {
  /**
   * Token field name
   *
   * @var string
   */
  static field = "leadid_token";

  /**
   * Timeout for fallback
   *
   * @var int
   */
  static timeout = 2000;

  constructor() {}  

  /**
   * Await Jornaya Token and resolve
   *
   * @return string
   */
  async get_token() {
    return new Promise(( resolve, reject ) => {
      let check = setInterval( () => {
        let input = document.getElementById(Jornaya.field);

        if ( input !== undefined && input !== null && input.value !== "" ) {
          clearInterval(check);
          clearTimeout(timeout);

          resolve(document.getElementById(Jornaya.field).value);
        }
      }, 25);

      // Fallback timeout
      let timeout = setTimeout( () => {
        console.error("Jornaya timeout");

        clearInterval(check);
        resolve("");
      }, Jornaya.timeout);
    });
  }
}