/**
 * Form Component
 *
 */
"use strict";

import Mutiny from "./mutiny.js";
import Pristine from "pristinejs/dist/pristine.min.js";
import SmartyAutocomplete from "./smarty-autocomplete.js"
import TestKit from "./testkit.js";
import TrustedForm from "./trusted-form.js";
import Jornaya from "./jornaya.js";
import UTM from "./utm.js";
import PostHog from "./posthog.js";
import Cookies from "js-cookie";

export default class AmericorForm
{
  /**
   * Helpers
   *
   * @var object
   */
  static mutiny       = new Mutiny();
  static testKit      = new TestKit();
  static trusted_form = new TrustedForm();
  static jornaya      = new Jornaya();
  static utm          = new UTM();
  static posthog      = new PostHog();

  /**
   * Config
   * 
   * @var object
   */
  static config = {
    channel: '',                    // Channel
    company: '',                    // Company
    vid: '',                        // Vendor ID
    jornaya: false,
  }

  /**
   * Define form field querySelectors
   *
   * Key should match Lead API field name return to properly validate fields
   *
   * @see https://admin.americor.com/swagger/doc
   * @var object
   */
  static form_fields = {
    general: 'div.general-error',
    offer: "input[name=offer_code]",
    debt: "select[name=debt]",
    firstName: "input[name=firstName]",
    lastName: "input[name=lastName]",
    phone: "input[name=phone], input[name=phoneHome]",
    email: "input[name=email]",
    address: "input[name=address]",   // new single address field for autocomplete
    street: "input[name=street]",
    city: "input[name=city]",
    state: "[name=state]",
    zip: "input[name=zip]",
    credit_authorization: "input[name=credit_authorization]",
    dateOfBirth: "input[name=dateOfBirth]",
    otpCode: "input[name=otpCode]",
  };
 
  /**
   * Default Pristine form validation config
   *
   * Change this to match form markup
   *
   * @see https://pristine.js.org/
   * @var object
   */
  static form_validation_config = {
    classTo: "form-group",
    errorClass: "has-error",
    successClass: "has-success",
    errorTextParent: "form-group",
    errorTextTag: "div",
    errorTextClass: "help-block-error",
  };

  /**
   * Lead UUID
   *
   * @var string
   */
  static uuid = sessionStorage.getItem("lead_uuid") ?? null;

  /**
   * Base Domain
   * 
   * @var string
   */
  static base_domain;

  /**
   * Visitor UUID
   * 
   * @var string
   */
  static visitor_uuid;

  /**
   * Constructor
   *
   * @param URL toolkit_base_url
   * @param object config
   * @returns
   */
  constructor( toolkit_base_url, config = {} )
  {
    const smarty_autocomplete = new SmartyAutocomplete( AmericorForm.form_fields );

    this.init( toolkit_base_url, config );
    this.init_form();
    this.prepopulate_form_fields();

    console.log(AmericorForm.config);
  }

  /**
   * Init config
   * 
   * @param URL toolkit_base_url
   * @param object config
   */
  init( toolkit_base_url, config )
  {
    AmericorForm.base_domain = this._get_base_domain();
    AmericorForm.config = { ...AmericorForm.config, ...config };

    AmericorForm.config.api_url         = toolkit_base_url + '/api';
    AmericorForm.config.partner_api_url = toolkit_base_url + '/api/partner';
    AmericorForm.config.vid             = parseInt( sessionStorage.getItem('vendor_id') ) || config.vid || '';
    AmericorForm.visitor_uuid           = config.visitor_uuid;
  }

  /**
   * Prepopulate form fields
   * 
   * @param object object
   */
  prepopulate_form_fields( object = null )
  {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let prepopWithUrlParams = {};

    urlParams.forEach(( value, key ) => {
      if ( AmericorForm.form_fields.hasOwnProperty(key) )
        prepopWithUrlParams[key] = value;
    });
    
    prepopWithUrlParams = Object.keys(prepopWithUrlParams).length ? JSON.stringify(prepopWithUrlParams) : null;

    let lead = object || prepopWithUrlParams || sessionStorage.getItem("lead") || Cookies.get('_lead');
    
    if ( ! lead ) return;
    
    lead = JSON.parse(lead);

    // If all address-related keys exist, build address field value
    if ( ['street', 'city', 'state', 'zip'].every( ( key ) => key in lead ) )
    {
      lead['address'] = `${lead['street']}, ` + `${lead['city']}, ` + `${lead['state']} ` + `${lead['zip']}`;
    }

    for ( var key in AmericorForm.form_fields )
    {
      if ( lead[key] === undefined ) continue;

      let fields = document.querySelectorAll( AmericorForm.form_fields[key] );
      let value  = ( key == 'debt' ) ? Math.floor(lead[key]) : lead[key];

      fields.forEach( ( field ) => {
        if ( key == 'debt') {
          let options = [...field.options].map(o => o.value);
              options = options.map((option) => parseInt(option));

          if ( options.includes(value) ) {
            field.value = value;
          }
        }
        else
        {
          field.value = value;
        }
      });
    }
  }

  /**
   * Init all forms
   * 
   */
  init_form()
  {
    const _self = this;
    const forms = document.querySelectorAll('form.americor-form');

    forms?.forEach(( form ) => {
      let pristine = ( ! form.classList.contains('no-pristine') ) ? new Pristine(form, AmericorForm.form_validation_config) : null;

      _self._init_form_submit_listener( _self, form, pristine );

      // Send form:init event
      form.dispatchEvent( new CustomEvent('form:init', {
        bubbles: true,
        detail: {
          form: form
        }
      } ) );
    });
  }

  /**
   * Initialize form submit listener
   * 
   * @param object _self 
   * @param object form 
   * @param Pristine pristine 
   */
  _init_form_submit_listener( _self, form, pristine )
  {
    const validateOnly = ( form.dataset.validateOnly && form.dataset.validateOnly == 'true' ) ? true : false;

    form.addEventListener("submit", function ( event ) {
      if ( pristine !== null )
      {
        if ( validateOnly && pristine.validate() ) return;

        event.preventDefault();

        // Validate form
        if ( ! pristine.validate() ) return;
      }

      const form_details = {
        currentStep: ( form.dataset.currentStep && form.dataset.currentStep != '' ) ? form.dataset.currentStep : null,
        isCompleted: ( form.dataset.isCompleted && form.dataset.isCompleted != '' ) ? form.dataset.isCompleted : false,
        leadAction: form.dataset.leadAction ?? 'create_lead',
        populateWithResponse: form.dataset.populateWithResponse ?? false,
        pristine: pristine,
        skipSubmit: ( form.dataset.skipSubmit && form.dataset.skipSubmit == 'true' ) ? true : false,
        validateOnly: validateOnly,
      }

      // Skip submit to API
      if ( form_details.skipSubmit == true )
      {
        // Dispatch form:skipsubmit event
        form.dispatchEvent( new CustomEvent('form:skipsubmit', {
          bubbles: true,
          detail: {
            form: form,
            form_details: form_details
          }
        } ) );
      }

      // Submit form to API
      else
      {
        _self._submit( form, form_details, event, ( response, form_data ) => {
          _self._process_completed_form( form, form_details, form_data, response );
          _self._populate_with_response( form, form_details, response );
        });
      }
    });
  }

  /**
   * Submit form data to API
   *
   * @param object    form
   * @param object    form_details
   * @param event     event
   * @param function  callback
   * @return Promise
   */
  async _submit( form, form_details, event, callback )
  {
    form.classList.add("loading");

    const formData  = new FormData(form);
    let form_data = Object.fromEntries(formData);

    // Add channel, company, vendorId
    form_data.channel  = AmericorForm.config.channel;
    form_data.company  = AmericorForm.config.company;
    form_data.vendorId = AmericorForm.config.vid;

    // Parse and set custom params
    form_data.params   = {};
    form_data          = this._get_button_values(event, form_data);
    form_data          = this._get_custom_params(formData, form_data);
    form_data.params   = { ...form_data.params, ...this._get_parameters() };
    form_data.mailCode = sessionStorage.getItem('offer_code') ?? '';

    // Parse out subId1, subId2, subId3
    for (const [key, value] of Object.entries(form_data.params))
    {
      if ( ['isTesting', 'subId1', 'subId2', 'subId3'].includes(key) )
      {
        form_data[key] = value.replace(/[^\w-]+/g,'');

        delete form_data.params[key]
      }
    }
    
    // If creating lead, add trusted form token
    if ( form_details.leadAction == 'create_lead' )
    {
      form_data.trusted_form_token = await AmericorForm.trusted_form.get_token();

      if ( AmericorForm.config.jornaya == true )
      {
        form_data.jornaya_leadid = await AmericorForm.jornaya.get_token();
      }
    }

    // If updating lead, add UUID
    if ( form_details.leadAction == 'update_lead' ) form_data.uuid = AmericorForm.uuid;

    // Add isCompleted status
    if ( form_details.isCompleted ) form_data.isCompleted = true;

    // If testing, inject testing parameter and save to sessionStorage
    if ( AmericorForm.testKit.is_active() )
    {
      let test_lead = sessionStorage.getItem('test_lead');
          test_lead = JSON.parse(test_lead);
      
      // Remove isCompleted status for testing so it doesn't get merged in with lead submission
      if ( test_lead && 'isCompleted' in test_lead )
      {
        delete test_lead.isCompleted;
      }
      
      test_lead                   = { ...test_lead, ...form_data };
      test_lead.testingParameters = AmericorForm.testKit.getParameters();

      sessionStorage.setItem('test_lead', JSON.stringify(test_lead));

      form_data = test_lead;
    }

    // Dispatch beforeSubmit event
    form.dispatchEvent( new CustomEvent('form:beforesubmit', {
      bubbles: true,
      detail: {
        form: form,
        form_details: form_details,
        form_data: form_data,
      }
    } ) );

    console.table(form_data);

    // Submit lead to API
    let submit = this._make_api_request( form, form_data, form_details );

    submit.then( ( response ) => {
      form.classList.remove('loading');

      if ( ! response ) return;
      
      // Dispatch afterSubmit event
      form.dispatchEvent( new CustomEvent('form:aftersubmit', {
        bubbles: true,
        detail: {
          form: form,
          form_details: form_details,
          form_data: form_data,
          response: response
        }
      } ) );

      console.table(response);
      
      // Set Lead UUID
      if ( response.uuid )
      {
        AmericorForm.uuid = response.uuid;

        sessionStorage.setItem('lead_uuid', response.uuid);
      }

      if ( form_details.leadAction == 'offer_code' )
      {
        sessionStorage.setItem('offer_code', form_data.offer_code.toUpperCase());

        if ( response.firstName || response.lastName )
        {
          sessionStorage.setItem('lead', JSON.stringify(response));

          if ( ! document.location.hostname.includes('debtclearusa') )
          {
            Cookies.set('_lead', JSON.stringify(response), { 
              expires: 30, 
              secure: true, 
              domain: AmericorForm.base_domain
            });
          }
        }
      }

      callback(response, form_data);
    });
  }

  /**
   * Make request to API
   *
   * @param object form
   * @param object data
   * @param array  form_details
   * @return Promise
   */
  async _make_api_request( form, data, form_details )
  {
    const _self = this;
    let params  = data.params || {};
    var url;

    // Set API URL by leadAction
    switch ( form_details.leadAction )
    {
      case 'create_lead':
        url = AmericorForm.config.api_url + '/create';
        break;

      case 'update_lead':
        url = AmericorForm.config.api_url + '/update';
        break;

      case 'offer_code':
        url = AmericorForm.config.api_url + '/offer';
        break;
        
      case 'get_zip':
        url = AmericorForm.config.api_url + '/zip';
        break;
      
      case 'get_quote':
        url = AmericorForm.config.api_url + '/quote';
        break;

      case 'partner_create_lead':
        url = AmericorForm.config.partner_api_url + '/create';
        break;

      case 'spinwheel_connect':
        url = AmericorForm.config.api_url + '/spinwheel/connect';
        break;

      case 'spinwheel_verify':
        url = AmericorForm.config.api_url + '/spinwheel/verify';
        break;
    }
    
    const response = await fetch( url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        action: form_details.leadAction,
        content: data,
        params: params,
        fullUrl: window.location.href
      })
      })
      .then( (response) => {
        if ( response.ok ) return response.json();

        document.dispatchEvent( new CustomEvent('form:error', {
          bubbles: true,
          detail: {
            form: form,
            form_details: form_details,
            response: response
          }
        } ) );

        _self._validate_response( form, response, form_details.pristine );
        
        return;
      })
      .catch( (error) => {
        console.log(error);

        document.dispatchEvent( new CustomEvent('form:error', {
          bubbles: true,
          detail: {
            form: form,
            form_details: form_details,
            response: error.response
          }
        } ) );

        _self._validate_response( form, error.response, form_details.pristine );
      });
        
      return response;
  }

  /**
   *
   * Validate API response and display error messages
   *
   * @param object form
   * @param object response
   * @param object pristine - Pristine instance to trigger callback error validation
   * @returns boolean
   */
  async _validate_response( form, response, pristine )
  {
    const resp_data = await response.json();
    const general = document.querySelector(AmericorForm.form_fields['general']);

    if ( general ) general.innerHTML = '';

    let general_errors = '';

    // 400 Bad Request
    if ( 'status' in response && response.status == 400 )
    {
      for ( const key in resp_data.errors )
      {
        const element = form.querySelectorAll(AmericorForm.form_fields[key]);

        // If no matching element, add to general error
        if ( ! element || element.length == 0 )
        {
          general_errors += resp_data.errors[key] + '<br>'; 
        }
        else
        {
          element.forEach(( el ) => {
            pristine.addError(el, resp_data.errors[key]);
          });
        }
      }

      // Show general errors
      if ( general_errors != '' && general )
      {
        general.innerHTML = '<div class="pristine-error help-block-error" role="alert">' + general_errors + '</div>';
      }

      return false;
    }

    // 500 Internal Server, 401 Unauthorized
    else if ( 'status' in response && ( response.status == 500 || response.status == 401 ) && general )
    {
      console.log('500 Internal Server, 401 Unauthorized');

      general.innerHTML = '<div class="pristine-error help-block-error" role="alert">' + resp_data.message + '</div>';

      return false;
    }

    return true;
  }

  /**
   * Check for custom params arrays
   * 
   * Use case would be checkboxes with multiple values
   * 
   * Name should be params[<key>]
   * 
   * @param object formData
   * @param object form_data
   * @return object
   */
  _get_custom_params( formData, form_data )
  {
    for ( const key in form_data )
    {
      if ( key.startsWith('params[') )
      {
        let params     = formData.getAll(key).toString();
        let params_key = key.replace('params[', '').replace(']', '');

        form_data.params[params_key] = params;

        delete form_data[key];
      }
    }

    return form_data;
  }

  /**
   * Check for submit button value and name
   * 
   * Use case would be if form submits on button click and value is on the button itself
   * 
   * Name should be params[<key>]
   * 
   * @param object event
   * @param object form_data
   * @return object
   */
  _get_button_values( event, form_data )
  {
    const button_value = event.submitter.value;
    const button_name  = event.submitter.name;

    if ( button_value && button_name )
    {
      if ( button_name.includes('params[') )
      {
        let params_key = button_name.replace('params[', '').replace(']', '');

        form_data.params[params_key] = button_value;
        
        delete form_data.button_name;
      }
      else
      {
        form_data[button_name] = button_value;
      }
    }

    return form_data;
  }

  /**
   * Get parameters with UTM and Mutiny values
   *
   * @returns object
   */
  _get_parameters()
  {
    const params ={
      ...AmericorForm.utm.get_parameters(), 
      ...AmericorForm.mutiny.get_parameters(),
      ...AmericorForm.posthog.get_parameters(),
      isLandingPage: true
    };

    if ( AmericorForm.visitor_uuid ) params['visitor_uuid'] = AmericorForm.visitor_uuid;

    return params;
  }

  /**
   * Process completed form
   * 
   * @param object form
   * @param object form_details 
   * @param object response 
   */
  _process_completed_form( form, form_details, form_data, response )
  {
    if ( ! form_details.isCompleted || ! response ) return;

    /**
     * Set lead public inputs in cache
     * 
     * Used to pre-populate form fields after session is complete for 30 days
     */
    let cachedLead = this._remove_non_form_fields(response);

    /**
     * Set API response in sessionStorage
     * 
     * Used for TY page logic, quote estimate rendering and additional on-page redirects
     */
    sessionStorage.setItem('lead', JSON.stringify(response));

    // Ignore debtclearusa properties
    if ( ! document.location.hostname.includes('debtclearusa') )
    {
      Cookies.set('_lead', JSON.stringify(cachedLead), {
        expires: 30, 
        secure: true, 
        domain: AmericorForm.base_domain
      });
    }

    // Dispatch isCompleted event
    form.dispatchEvent( new CustomEvent('form:iscompleted', {
      bubbles: true,
      detail: {
        form: form,
        form_details: form_details,
        form_data: form_data,
        response: response
      }
    } ) );
  }

  /**
   * Populate form with response
   * 
   * @param object form
   * @param object form_details 
   * @param object response 
   */
  _populate_with_response( form, form_details, response )
  {
    if ( ! form_details.populateWithResponse ||
         ! response ||
         typeof response !== 'object'
    ) return;

    this.prepopulate_form_fields( JSON.stringify(response) );
  }

  /**
   * Remove non form fields from Lead response
   * 
   * @param object data 
   * @return object
   */
  _remove_non_form_fields( data )
  {
    let cachedLead = {};

    Object.keys(data).forEach(( key ) => {
      if ( AmericorForm.form_fields.hasOwnProperty(key) )
        cachedLead[key] = data[key];
    });

    return cachedLead;
  }

  /**
   * Get base domain for Cookie setting
   * 
   * @return string
   */
  _get_base_domain()
  {
    if ( document.location.hostname.includes('americor.com') )
      return '.americor.com';

    if ( document.location.hostname.includes('credit9.com') )
      return '.credit9.com';

    if ( document.location.hostname.includes('lib1st.com') )
      return '.lib1st.com';

    return document.location.hostname
  }
}
