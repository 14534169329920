/**
 * Not Qualified - Logic
 *
 */
"use strict";

export default class AmericorNQTY 
{
  /**
   * Partials 
   * 
   * @var object
   */
  static partials = document.querySelectorAll("section.not-qualified");

  /**
   * Objects
   *  - Edge case keys -- Not in lead
   * 
   * @var object
   */
  static objects = {
    'TIMESTAMP': Date.now(),
  };

  /**
   * Constructor
   * 
   */
  constructor() {
    this.render_partial();
  }

  /**
   * Render Partial
   *   - Renders partial based on what partnerId is set
   *
   */
  render_partial() {
    const lead = JSON.parse(sessionStorage.getItem('lead'));

    AmericorNQTY.partials.forEach( item => {
      if ( ! lead || lead['partnerId'] == null ) {
        if ( item.classList.contains('default-ty') ) item.classList.add('active');
        return
      }
      else {
        const id = item.dataset.partnerId;
        const link = item.dataset.link;
        const vendor_id = JSON.parse(sessionStorage.getItem('vendor_id')) || '';
        const vendor_id_format = item.dataset.vendorIdFormat ? parseInt(item.dataset.vendorIdFormat) : 0;

        if ( ! id || ! link ) return;

        // Only activate one partial based on partner id
        if ( id == lead['partnerId'] ) item.classList.add('active');

        // Tranform all found links from partials regardless of what partial is being shown
        item.querySelector('#offer-link').href = this._make_link(lead, link, vendor_id, vendor_id_format);
      }
    });
  }

  /**
   * Make Link
   *
   * @param {object} lead 
   * @param {string} link
   * @param {string} vendor_id
   * @param {integer} vendor_id_format
   * @returns {string} 
   */
  _make_link(lead, link, vendor_id, vendor_id_format = null) {
    for ( const match of link.matchAll(/{(.*?)}/g) ) {
      if (match[1] in lead) {
        if (match[1] == 'phone') {
          link = link.replace(match[0], lead[match[1]].replace(/[^+\d]+/g, ""));
        }
        else {
          link = link.replace(match[0], lead[match[1]]);
        }
      }
      else if (match[1] == 'vendor_id' ) {
        link = link.replace(match[0], vendor_id.toString().padStart(vendor_id_format, '0'));
      }
      else if (match[1] in AmericorNQTY.objects) {
        link = link.replace(match[0], AmericorNQTY.objects[match[1]]);
      }
    }

    return link;
  }
}
